import React, { useState, useCallback } from 'react';
import { BorrowerLeadGenerationTool } from 'borrower-lead-generation-tool';
import './index.css';

export default function LeadCaptureForm() {
    // stores the input field reference in a way that allows us to conditionally render
    // content or scroll to the element on button click.
    const [node, setNode] = useState(null);

    // watches for changes in the form.
    const formRef = useCallback(node => {
      // node refers to the name input field in the BorrowerLeadGenerationTool
      setNode(node);
    }, []);

    return (
        <div className="formWrapper">
            {/* Conditionally render the form title if the form is rendered. */}
            {node && (
                <div className="formHeader">
                    Contact Us
                </div>
            )}
            {/* Wrap the form itself to more clearly apply css styles. */}
            <div className="formBody">
                <BorrowerLeadGenerationTool
                    leadSource="NET007"
                    loanOfficerUid="CKTC"
                    mainBtnName="Send Message"
                    statesDomain={process.env.REACT_APP_STATE_DOMAIN}
                    webPageURL={process.env.REACT_APP_STATE_DOMAIN}
                    ref={formRef}
                    domainNameConsent="lowrates.com"
                    language="en"
                />
            </div>
            {node && (
                <div className="formFooter text-center py-2">
                    <a href='tel:+18447869378'>1-(844) 786-9378</a>
                </div>
            )}
        </div>
    );
}
