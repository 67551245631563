import React, { useEffect, useState } from 'react';
import styles from './index.module.css'
import { Container, Col, Row } from 'react-bootstrap';
import StepperStatusBar from '../../components/StepperStatusBar';
import MobileStepper from '../../components/MobileStepper';
import { Helmet } from 'react-helmet';
const GetRates = () => {

    return (

        <article>
            <Helmet>
                
            <title>Get My Rate | LowRates</title>
            <meta name="description" content="LowRates, Mortgage Loans"/>
            <meta name="keywords" content="LowRates, Mortgage, Loans, Swmc, SunWestMortgage, Mortgage Possible"/>


            </Helmet>
       
        
        <Container fluid className={styles.faq_container}>
         
            <div className={styles.calculators_frame}>
                <div id='bb-821fa'>
                <iframe id="bb-iframe" allowfullscreen="true" scrolling="no" src="https://app.bankingbridge.com/embed/standalone?app_key=2011981542" referrerpolicy="unsafe-url" style={{height: '750px', transition: 'height 0.15s ease 0s', width : '100%', frameBorder:'0'}}></iframe>

                </div>
            {/* <MobileStepper /> */}


            </div>
        </Container>
        </article>
    );
}

export default GetRates;